import React, { useState, useRef, useEffect } from "react"
// import { RichText } from 'prismic-reactjs'

// import htmlSerializer from '../../utils/htmlSerializer';

const AccordionItem = ({ id, title, content }) => {
  const contentContainer = useRef();
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    setIsAnimating(!isAnimating);
    setIsExpanded(!isExpanded);
    setHeight(contentContainer.current.offsetHeight);
    // should remove the max height when it's not needed anymore for the animation but this isn't working right now
    // setTimeout(() => {
    //   setIsAnimating(false);
    // }, 800)
  }

  useEffect(() => {
    setHeight(contentContainer.current.offsetHeight);
  }, [])

  return (
    <li className={`c-accordion__item`}
        data-sal="slide-up" 
        data-sal-duration="500">
      <h3>
        <button 
          onClick={() => handleClick()} 
          className={`c-accordion__heading`} 
          id={`heading-${id}`} 
          aria-expanded={isExpanded} 
          aria-controls={`panel-${id}`}
          aria-label={isExpanded ? `Show less about ${title}` : `Learn more about ${title}`}>
          <span>{title}</span>
          <div className={`c-accordion__action`}>
          </div>
        </button>
      </h3>
      <div className={`c-accordion__content`} style={isAnimating ? { maxHeight: height } : null} aria-hidden={!isExpanded} id={`panel-${id}`} aria-describedby={`heading-${id}`}>
        {/* <RichText render={content} htmlSerializer={htmlSerializer} /> */}
        <div ref={contentContainer} className={`c-accordion__content__inner`}>
          <p>{content}</p>
          </div>
      </div>
    </li>
  )
}

export default AccordionItem
