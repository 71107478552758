import React from "react"

const Accordion = ({ children }) => {

  return (
    <ul className={`c-accordion u-list--reset`}>
      {children}
    </ul>
  )
}

export default Accordion
