import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../utils/htmlSerializer"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import Button from "../components/shared/button"
import Banner from "../components/widgets/banner"
import Accordion from "../components/widgets/accordion"
import AccordionItem from "../components/widgets/accordionItem"

import Reveal from "react-reveal/Reveal"

const ApproachPage = ({ data }) => {
  const doc = data.prismic.allApproachs.edges.slice(0, 1).pop()
  if (!doc) return null

  return (
    <Layout pageTitle={doc.node.page_heading[0].text}>
      <SEO
        title={
          doc.node.meta_title
            ? doc.node.meta_title
            : doc.node.page_heading[0].text
        }
        image={doc.node.meta_image}
        description={doc.node.meta_description}
      />

      <Banner content={doc.node.banner_title} color="red" />

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section className={`grid-container react-reveal-cascade`}>
          <div className={`grid-x`}>
            <div className={`cell`}>
              <RichText
                render={doc.node.main_content}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section className={`grid-container`}>
          <div className={`grid-x`}>
            <div className={`cell`}>
              <RichText
                render={doc.node.how_heading}
                htmlSerializer={htmlSerializer}
              />
            </div>
            <div className={`cell small-12`}>
              <Accordion>
                {doc.node.how_group.map((item, index) => (
                  <AccordionItem
                    key={index}
                    id={`how${index}`}
                    title={item.title}
                    content={item.content}
                  />
                ))}
              </Accordion>
            </div>
          </div>
        </section>
      </Reveal>

      <aside className={`c-aside grid-container`}>
        <div className={`c-aside__content grid-x`}>
          <Reveal effect="fadeInUp" fraction={0.05}>
            <div className={`cell`}>
              <RichText
                render={doc.node.bottom_cta_heading}
                htmlSerializer={htmlSerializer}
              />

              <Button
                text={doc.node.bottom_cta_text}
                link={doc.node.bottom_cta_link}
                emoji={doc.node.bottom_cta_emoji}
                emojiLabel={doc.node.bottom_cta_emoji_label}
              />
            </div>
          </Reveal>
        </div>
      </aside>
    </Layout>
  )
}

export const query = graphql`
  query ApproachPageQuery {
    prismic {
      allApproachs {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            meta_imageSharp {
              publicURL
            }
            page_title
            page_heading
            banner_title
            main_content
            how_heading
            how_group {
              title
              content
            }
            bottom_cta_heading
            bottom_cta_text
            bottom_cta_link {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            bottom_cta_emoji_label
            bottom_cta_emoji
          }
        }
      }
    }
  }
`

export default ApproachPage
